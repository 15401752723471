import { render, staticRenderFns } from "./EarnMoney.vue?vue&type=template&id=482e5395&scoped=true&"
import script from "./EarnMoney.vue?vue&type=script&lang=js&"
export * from "./EarnMoney.vue?vue&type=script&lang=js&"
import style0 from "./EarnMoney.vue?vue&type=style&index=0&id=482e5395&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482e5395",
  null
  
)

export default component.exports