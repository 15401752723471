<template>
  <div id="gray-copy-input" @click.prevent="copyOnClipboard">
    <div class="header">
      لینک دعوت
    </div>
    <div class="input-container">
      <div class="content-wrapper">
        https://exonyx.ir/ref/27567
      </div>
      <div class="image-wrapper">
        <img src="../../assets/images/carbon_copy-file.svg" alt="" />
      </div>
    </div>
    <div class="Link-copy-tooltip-container" v-if="showPopUp">
      <div class="link-copy-tooltip__content">
        لینک دعوت کپی شد
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrayCopyLinkInput",
  data() {
    return {
      showPopUp: false
    };
  },
  methods: {
    copyOnClipboard() {
      this.showPopUp = true;
      setTimeout(() => {
        this.showPopUp = false;
      }, 500);
    }
  }
};
</script>

<style scoped>
#gray-copy-input {
  width: 100%;
  /*min-width: 280px;*/
  min-height: 30px;
  position: relative;
}

.header {
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  margin-bottom: 10px;
}

.input-container {
  background: rgba(31, 60, 136, 0.2);
}

.input-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  /*padding: 2px 10px;*/
}

.content-wrapper {
  flex-grow: 1;
  padding: 3px 10px;
}

.image-wrapper {
  flex-basis: 13%;
  display: flex;
  align-items: center;
  background: rgba(31, 60, 136, 0.2);
  justify-content: center;
  padding: 3px;
}

.content-wrapper {
  color: rgba(18, 18, 18, 0.7);
  font-weight: normal;
  font-size: 14px;
}

.Link-copy-tooltip-container {
  padding: 5px 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 135px;
  position: absolute;
  left: -20%;
  bottom: -65%;
  background: #ffffff;
}

.link-copy-tooltip__content {
  font-weight: normal;
  font-size: 14px;
}
</style>
