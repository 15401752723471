<template>
  <div id="earn-money">
    <PanelHeader title="کارت های بانکی" />
    <div class="earn-money-container">
      <div class="earn-money-information-card">
        <div class="item-wrapper">
          <img src="../assets/images/add-friend-count.svg" alt="" />
          <div class="item-title">۷نفر</div>
          <div class="item-content">تعداد دوستان دعوت شده</div>
        </div>
        <div class="item-wrapper">
          <img src="../assets/images/order-count.svg" alt="" />
          <div class="item-title">۱۶</div>
          <div class="item-content">تعداد کل سفارشات</div>
        </div>
        <div class="item-wrapper">
          <img src="../assets/images/commission.svg" alt="" />
          <div class="item-title">۰تومان</div>
          <div class="item-content">مبلغ پورسانت</div>
        </div>
        <div class="item-wrapper">
          <img src="../assets/images/investment.svg" alt="" />
          <div class="item-title">۰تومان</div>
          <div class="item-content">میانگین پورسانت هر سفارش</div>
        </div>
      </div>
      <div class="earn-money-invite-card">
        <div class="first-row">
          <div class="earn-money-invite-header">
            معرفی و درآمد از اکسچنج از
          </div>
          <div class="earn-money-invite-information">
            با معرفی اکسچنج از به دوستان خود از هر بار خرید دوستانتان پورسانت
            دریافت کنید.
          </div>
          <div class="earn-money-invite-link-code-wrapper">
            <div class="earn-money-invite-link">
              <GrayCopyLinkInput />
            </div>
            <div class="earn-money-invite-code">
              <GrayCopyCodeInput />
            </div>
          </div>
        </div>
        <div class="second-row">
          <img src="../assets/images/Invite-pana.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import GrayCopyLinkInput from "@/components/input/GrayCopyLinkInput";
import GrayCopyCodeInput from "@/components/input/GrayCopyCodeInput";

export default {
  components: { GrayCopyCodeInput, GrayCopyLinkInput, PanelHeader }
};
</script>

<style scoped>
#earn-money {
  /*width: calc(100% - 250px);*/
  /*margin-right: auto;*/
  width: 100%;
}

.earn-money-container {
  /*margin-top: 20px;*/
  padding: 0 10px;
  /*height: 100vh;*/
}

.earn-money-information-card {
  width: 100%;
  min-height: 205px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  padding: 20px;
  gap: 40px;
}

.earn-money-invite-card {
  width: 100%;
  min-height: 265px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  margin-bottom: 170px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-width: 200px;
}

.item-title {
  font-weight: bold;
  font-size: 22px;
  color: #121212;
  margin-top: 23px;
}

.item-content {
  font-weight: bold;
  font-size: 16px;
  color: #121212;
}

.earn-money-invite-header {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}

.earn-money-invite-information {
  font-size: 16px;
  font-weight: normal;
  color: #121212;
  margin-top: 20px;
}

.first-row {
  flex-grow: 1;
  width: 100%;
}

.second-row {
  flex-basis: 30%;
  display: none;
}

.earn-money-invite-link-code-wrapper {
  margin-top: 67px;
  /*margin-right: 75px;*/
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
}

.earn-money-invite-link {
  width: 280px;
}

/*.earn-money-container {*/
/*  padding: 0 10px;*/
/*}*/

.earn-money-information-card {
  margin-top: 20px;
}

.earn-money-invite-code {
  width: 280px;
}

@media (min-width: 960px) {
  .second-row {
    display: block;
  }
}

@media (min-width: 1140px) {
  #earn-money {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .second-row {
    display: block;
  }
}
</style>
